/** @jsx jsx */
import { jsx, Flex } from "theme-ui"
import { Link } from "gatsby"

const Navigation = () => (
  <nav>
    <Flex
      sx={{
        listStyle: 'none',
        fontFamily: 'heading',
        fontWeight: 300
      }}
      as="ul">
        <li>
          <Link
            sx={{
              p: 3,
              color: 'white'
            }}
            to="/">
              Our Lawyers
          </Link>
        </li> 
        <li>
          <Link
            sx={{
              p: 3,
              color: 'white'
            }}
            to="/practice">
              Practice Areas
          </Link>
        </li> 
        <li>
          <Link
            sx={{
              p: 3,
              color: 'white'
            }}
            to="/">
              Our Story
          </Link>
        </li> 
        <li>
          <Link
            sx={{
              p: 3,
              color: 'white'
            }}
            to="/">
              Contact Us
          </Link>
        </li> 
    </Flex>
  </nav>
)

export default Navigation