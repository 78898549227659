/** @jsx jsx */
import {
  jsx,
  Flex,
  Box,
  Label,
  Input,
  Select,
  Textarea,
} from "theme-ui"
import { useStaticQuery, graphql } from "gatsby"
import Button from "./button"



const ContactForm = () => {
  const { allService: { nodes: services } } = useStaticQuery(graphql`
    query {
      allService {
        nodes {
          name
          id
        }
      }
    }
  `)
  return (
    <Box
      sx={{
        color: 'white'
      }}
      as='form'
      onSubmit={e => e.preventDefault()}>
      <Flex>
        <Box
          sx={{
            mr: 2,
            width: ['full', '1/2']
          }}>
          <Input
            variant="inputs.inline"
            name='name'
            id='name'
            placeholder='Full Name'
            mb={3}
          />
        </Box>
        <Box
          sx={{
            ml: 2,
            width: ['full', '1/2']
          }}>
          <Input
            variant="inputs.inline"
            name='email'
            id='email'
            placeholder='Email'
            mb={3}
          />
        </Box>
      </Flex>
      <Label htmlFor='sound'>Practice Area</Label>
      <Select
        name='practice'
        id='practice'
        sx={{
          mb: 3,
          '& + svg': {
            fill: 'primary',
            mt: "-15px"
          }
        }}>
          { services.map(({ name, id}) => (
            <option key={id}>
              { name }
            </option>
          ))}
      </Select>
      <Textarea
        variant="inputs.inline"
        name='comment'
        id='comment'
        placeholder='Message'
        rows='6'
        sx={{
          p: 2,
          mb: 4
        }}
      />
      <Button size={1}>
        Submit
      </Button>
  </Box>
  )
}

export default ContactForm