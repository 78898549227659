/** @jsx jsx */
import { jsx, Styled, Flex, Box } from "theme-ui"
import { Link } from "gatsby"
import Logo from "../images/logo.inline.svg"
import Navigation from "../components/navigation"
import PropTypes from "prop-types"

const Header = ({ siteTitle }) => (
  <Flex
    as="header"
    sx={{
      bg: theme => theme.colors.primary
    }}>
    <Box
      sx={{
        bg: theme => theme.colors.darkBlue,
      }}>
      <Logo 
        sx={{
          width: '115px',
          m: 4
        }} />
    </Box>
    <Box
      sx={{
        px: 5,
        flexGrow: 1
      }}>
      <Flex
        sx={{
          height: 'full',
          alignItems: 'center'
        }}>
        <Box>
          <Styled.h1 sx={{
            m: 0
          }}>
            <Link
              sx={{
                color: 'white',
                '&:hover': {
                  color: 'secondary'
                }
              }}
              to="/">
              {siteTitle}
              <Styled.h6
               sx={{
                 textAlign: 'right',
                 color: 'white',
                 fontWeight: '100'
               }}>
                Attorneys at Law Serving the Midwest 
              </Styled.h6>
            </Link>
          </Styled.h1>
        </Box>
        <Box
          sx={{
            marginLeft: 'auto'
          }}>
          <Navigation />
        </Box>
      </Flex>
    </Box>
  </Flex>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
