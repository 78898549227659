/** @jsx jsx */
import { jsx } from "theme-ui"

const Button = ({ children, type = 'default', size = 2 }) => {
  const style = {
    fontFamily: 'heading',
    fontWeight: 400,
    px: 4,
    py: 2,
    fontSize: size,
    outline: 'none',
    '&:focus': {
      outline: 'none'
    }
  }
  switch(type){
    case 'default':
      style.bg = 'secondary'
      style.color = 'white'
      style.borderStyle = 'solid'
      style.borderColor = 'secondary'
      style.borderWidth = 1
      style['&:hover'] = {
        bg: 'transparent',
        color: 'secondary', 
        cursor: 'pointer'
      }
      break;
    case 'inverse':
      style.bg = 'transparent'
      style.color = 'secondary'
      style.borderStyle = 'solid'
      style.borderColor = 'secondary'
      style.borderWidth = 1
      style['&:hover'] = {
        bg: 'secondary',
        color: 'white', 
        cursor: 'pointer'
      }
      break
    default:
  }
  return (
    <button
      sx={style}>
        { children } 
    </button>
  )
}

export default Button