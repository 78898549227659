/** @jsx jsx */
import { jsx, Container, Flex, Box, Styled } from "theme-ui"
import { Link, useStaticQuery, graphql } from "gatsby"

import ContactForm from "./contactForm"
import Logo from "../images/logo.inline.svg"
import MapIcon from "../images/icons/map-marker-alt.inline.svg"
import PhoneIcon from "../images/icons/phone.inline.svg"
import EnvelopeIcon from "../images/icons/envelope.inline.svg"

const Footer = () => {
  const { site: { siteMetadata: { title } }, allService } = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          title
        }
      }
      allService {
        nodes {
          id
          slug
          name
        }
      }
    }
  `)
  return (
    <Flex
      as="footer"
      sx={{
        widht: 'full',
        flexDirection: 'column'
      }}>
      <div
        sx={{
          bg: 'primary',
          py: 5
        }}>
        <Container
          sx={{
            px: 4
          }}>
          <Flex sx={{
            flexWrap: 'wrap',
            mx: -4
          }}>
            <Box
              sx={{
                px: 4,
                width: ['full', '1/2']
              }}>
              <Styled.h2
                sx={{
                  color: 'secondary'
                }}>
                Contact Us
              </Styled.h2>
              <p
                sx={{
                  color: 'white'
                }}>
                If you need legal representation in any area of practice please reach out for a free consultation and to discuss your options.
                </p>
              <Flex
                sx={{
                  listStyle: 'none',
                  flexDirection: 'column',
                  mt: 4,
                  pl: 0,
                  color: 'white'
                }}
                as="ul">
                <Flex
                  sx={{
                    alignItems: 'center',
                    mb: 3
                  }}>
                  <Box>
                    <MapIcon
                      sx={{
                        width: '25px',
                        fill: 'secondary'
                      }}
                    />
                  </Box>
                  <Box
                    sx={{
                      ml: 3
                    }}>
                    123 Broadway N, Fargo ND, 58102
                  </Box>
                </Flex>
                <Flex
                  sx={{
                    alignItems: 'center',
                    mb: 3
                  }}>
                  <Box>
                    <PhoneIcon
                      sx={{
                        width: '25px',
                        fill: 'secondary'
                      }}
                    />
                  </Box>
                  <Box
                    sx={{
                      ml: 3
                    }}>
                    +1 (701) 235-5555
                  </Box>
                </Flex>
                <Flex
                  sx={{
                    alignItems: 'center'
                  }}>
                  <Box>
                    <EnvelopeIcon
                      sx={{
                        width: '25px',
                        fill: 'secondary'
                      }}
                    />
                  </Box>
                  <Box
                    sx={{
                      ml: 3
                    }}>
                    info@fargo.legal
                  </Box>
                </Flex>
              </Flex>
            </Box>
            <Box
              sx={{
                px: 4,
                width: ['full', '1/2']
              }}>
              <ContactForm />
            </Box>
          </Flex>
        </Container>
      </div>
      <div
        sx={{
          bg: 'darkBlue',
          py: 5
        }}>
        <Container>
          <Flex>
            <Box sx={{
              width: ['full', '1/4'],
              textAlign: 'center'
            }}>
              <div
                sx={{
                  mb: 2
                }}>
                <Logo
                  sx={{
                    width: '150px'
                  }} />
              </div>
              <div
                sx={{
                  mb: 2
                }}>
                <Styled.h2
                  sx={{
                    color: 'secondary'
                  }}>
                  {title}
                </Styled.h2>
              </div>
              <div
                sx={{
                  color: 'gray.5',
                  fontSize: 0
                }}>
                © {new Date().getFullYear()}, Built by
                {` `}
                <a
                  sx={{
                    color: 'gray.4'
                  }}
                  href="https://hashinteractive.com">Hash Interactive</a>
              </div>
            </Box>
            <Box
              sx={{
                ml: 'auto',
                width: ['full', '1/6']
              }}>
              <Styled.h6
                sx={{
                  color: 'gray.1',
                  textTransform: 'uppercase',
                  fontSize: '1.1rem'
                }}>
                Navigation
                  </Styled.h6>
              <Flex
                sx={{
                  listStyle: 'none',
                  flexDirection: 'column',
                  p: 0
                }}
                as="ul">
                <li>
                  <Link
                    to="/"
                    sx={{
                      color: 'gray.5',
                      textDecoration: 'none',
                      display: 'inline-block',
                      py: 1
                    }}>Home</Link>
                </li>
                <li>
                  <Link
                    to="/"
                    sx={{
                      color: 'gray.5',
                      textDecoration: 'none',
                      display: 'inline-block',
                      py: 1
                    }}>Our Lawyers</Link>
                </li>
                <li>
                  <Link
                    to="/"
                    sx={{
                      color: 'gray.5',
                      textDecoration: 'none',
                      display: 'inline-block',
                      py: 1
                    }}>Practice Areas</Link>
                </li>
                <li>
                  <Link
                    to="/"
                    sx={{
                      color: 'gray.5',
                      textDecoration: 'none',
                      display: 'inline-block',
                      py: 1
                    }}>Our Story</Link>
                </li>
                <li>
                  <Link
                    to="/"
                    sx={{
                      color: 'gray.5',
                      textDecoration: 'none',
                      display: 'inline-block',
                      py: 1
                    }}>Contact Us</Link>
                </li>
              </Flex>
            </Box>
            <Box
              sx={{
                width: ['full', '1/6']
              }}>
              <Styled.h6
                sx={{
                  color: 'gray.1',
                  textTransform: 'uppercase',
                  fontSize: '1.1rem'
                }}>
                Law Practice
                  </Styled.h6>
              <Flex
                sx={{
                  listStyle: 'none',
                  flexDirection: 'column',
                  p: 0
                }}
                as="ul">
                {allService.nodes.map(service => (
                  <li key={service.id}>
                    <Link
                      to={`/${service.slug}`}
                      sx={{
                        color: 'gray.5',
                        textDecoration: 'none',
                        display: 'inline-block',
                        py: 1
                      }}>{service.name}</Link>
                  </li>
                ))}
              </Flex>
            </Box>
            <Box
              sx={{
                width: ['full', '1/6']
              }}>
            </Box>
          </Flex>
        </Container>
      </div>
    </Flex>
  )
}

export default Footer